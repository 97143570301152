import Vue from 'vue'
import VueRouter from 'vue-router'
import { storeToRefs } from "pinia";
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
import Callback from '../views/auth/Callback.vue'
import Unauthorized from '../views/auth/Unauthorized.vue'
import authService from '../services/authservice';
import DataService from "../services/DataService";
import common from '../assets/common/vue-common.js'
Vue.use(VueRouter)
const routes = [
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },
    {
        path: "/HRReport/Index",
        name: "HRReport",
        component: () => import('../views/HrReport/Index.vue'),
        meta: {
            checkPrivilege: false,
            title: 'HR Report',
            breadcrumb: [{ name: "HR Report" }]
        }
    },
    {
        path: "/AssetReport/Index",
        name: "AssetReport",
        component: () => import("../views/AssetReport/Index.vue"),
        meta: {
          title: "Product Report",
          breadcrumb: [{ name: "Product Report", link: "/AssetReport/Index" }],
        },    
    },
    {
        path: "/CRMReport/Index",
        name: "CRMReport",
        component: () => import('../views/CRMReport/Index.vue'),
        meta: {
            title: 'CRM Report',
            breadcrumb: [{ name: "CRM Report" }]
        }
    },
    {
        path: "/TimeAttendanceReport/AttendanceCardReport",
        name: "TimeAttendanceReport",
        component: () => import('../views/AttendanceCardReport.vue'),
        meta: {
            checkPrivilege: false,
            title: 'AttendanceCardReport',
            breadcrumb: [{ name: "AttendanceCardReport" }]
        }
    },

    {
        path: "/ExpenseReport/Index",
        name: "ExpenseReport",          
        component: () => import('../views/ExpenseReport/Index.vue'),
        meta: { title: 'Expense Report',
        breadcrumb: [
            { name: "Expense Report" }
        ]}
    },
    {
        path: "/TicketReport/Index",
        name: "TicketReport",
        component: () => import('../views/TicketReport/Index.vue'),
        meta: {
            checkPrivilege: false,
            title: 'TicketReport',
            breadcrumb: [{ name: "TicketReport" }]
        }
    },
    {
        path: "/ApprovalReport/Index",
        name: "ApprovalReport",
        component: () => import('../views/ApprovalReport/Index.vue'),
        meta: {
            checkPrivilege: false,
            title: 'ApprovalReport',
            breadcrumb: [{ name: "ApprovalReport" }]
        }
    },
    {
        path: "/BIReport/Index",
        name: "BIReport",
        component: () => import('../views/BIReports/Index.vue'),
        meta: {
            checkPrivilege: false,
            title: 'BIReport',
            breadcrumb: [{ name: "BIReport" }]
        }
    },
    {
        path: "/TimerReports/Index",
        name: "TimerReports",
        component: () => import('../views/TimerReports/Index.vue'),
        meta: {
            checkPrivilege: false,
            title: 'TimerReports',
            breadcrumb: [{ name: "TimerReports" }]
        }
    },
    {
        path: "/PerformanceReviewReport/Index",
        name: "PerformanceReviewReport",
        component: () => import('../views/PerformanceReviewReport.vue'),
        meta: {
            checkPrivilege: false,
            title: 'TimerReports',
            breadcrumb: [{ name: "TimerReports" }]
        }
    },
    {
        path: "/TrainingReport/Index",
        name: "TrainingReport",
        component: () => import('../views/TrainingReport.vue'),
        meta: {
            checkPrivilege: false,
            title: 'TimerReports',
            breadcrumb: [{ name: "TimerReports" }]
        }
    }
  
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
}) 
const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {    
    document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
    document.getElementById("divProgress1").style.display = 'none';
    document.getElementById("talygenfooter").style.display = 'block';
    if (to.path == '/unauthorized') {
        next()
    }
    else {
        const ustore = useloggedUserInfo();
        let app = ustore.GetUserInfo;
        if (app) {
            let isCheckPrivilege = true;
            if(to.meta.hasOwnProperty('checkPrivilege'))
            {
                isCheckPrivilege = to.meta.checkPrivilege;
            }
            if(isCheckPrivilege) {
                let controllerName = "", actionName = "";
                if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                    controllerName = to.meta.controllerName;
                    actionName = to.meta.actionName;
                }
                else{
                    let pathArray = to.path.split("/");
                    if(pathArray.length > 2){
                        controllerName = pathArray[1];
                        actionName = pathArray[2];
                    } else{
                        controllerName = pathArray[0];
                        actionName = pathArray[1];
                    }
                }
                if(controllerName != "" && actionName != "") {
                    DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                        if (value.data)
                            next()
                        else
                            //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                            next('/unauthorized');
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else {
                    next()
                }
            } else {
                next()
            }
        }
        else {
            //authService.authenticate(to.path).then(() => {
                console.log('authenticating a protected url:' + to.path);
                next();
           // });
        }
    }
});
export default router

